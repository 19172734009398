import React from "react";
import Services from "../../components/Home/Services";
import WhyAstro from "../../components/Home/WhyAstro";
import NavTabs from "../NavTabs";
import { Parallax, Background } from 'react-parallax';
import './parallax.css';
import AboutPage from "../About/AboutPage";
import QA from "../../components/Website/QA";
// import Tilt from 'react-vanilla-tilt';

const Home = () => {
  return (
    <>
    <NavTabs/>
    <div className="home-page">
        <Parallax strength={300} className="outerParallax">
          <Background className="parallax">
              <img src="../home-bg-2.png" alt="fill murray" />
          </Background>
          <ul className="astroLogo-ul">
            <li className="text-center">
              <img src="../astronet-logo-new.png" className="homeLogo"/>
            </li>
          </ul>
        </Parallax>
        <div className="background-blue-3 text-center pt-2">
          <h1 className="orange pt-3">PREMIER DIGITAL MARKETING</h1>
        </div>
        <div className="background-blue-4">
          <div className="row justify-content-center pt-3 ">
              <a className="startedBtn mt-2" href="/getstarted">GET STARTED</a>
              </div>
          </div>
       
        <div className="background-blue-4"/>
          <div className="row background-purple whyAstro">
            <WhyAstro />
          </div>

        <div className="background-blue"/>
        <div className="background-blue-2"/>

        <div className="services-bg">
          <Services />
        </div>

        <div className="background-blue-3">
          
        </div>
        <div className="background-blue-4">
          <div className="row justify-content-center pt-3 ">
              
              </div>
          </div>


        <Parallax bgImage="../home-bg-2.png" 
        bgImageAlt="the cat" strength={-100} className="">
              <ul className="text-center justify-content-center ul-icon">
                  <a href="https://www.instagram.com/astronetdigital/?igshid=1ri7bxnnjye7j"><img src="./Icons/insta.png" className="icon"/></a>
                  <a href="mailto:team@astronetdigital.com"><img src="./Icons/email.png" className="icon"/></a>
                  <a href="https://twitter.com/astronetdigital?s=11"><img src="./Icons/twitt.png" className="icon"/></a>
                  <a href="https://m.facebook.com/ASTRONETDigital/?refsrc=https%3A%2F%2Fm.facebook.com%2Flogin%2Fdevice-based%2Fpassword%2F&_rdr"><img src="./Icons/fb.png" className="icon"/></a>
                  <a href="https://linkedin.com/astronet-digital"><img src="./Icons/in.png" className="icon"/></a>
              </ul>
        </Parallax>
        <div className="background-blue-3 text-center pt-2">
          <h1 className="orange pt-3">PREMIER DIGITAL MARKETING</h1>
        </div>
        <div className="background-blue-4">
          <div className="row justify-content-center pt-3 ">
              <a className="startedBtn mt-2" href="/getstarted">GET STARTED</a>
              </div>
          </div>
          <div className="background-blue-5"/>
       
        <AboutPage/>
        <div className="background-blue-5"/>
        
        <div className="background-blue-2"/>
        
      </div>
    </>
  );
};

export default Home;
