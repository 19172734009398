import React from "react";
import { Link, useLocation } from "react-router-dom";
import Toast from "../utils/toast/Toast.jsx";

function NavTabs() {
  // We'll go into the Hooks API later, for now, we are just using some code
  // from the react-router docs (https://reacttraining.com/react-router/web/api/Hooks/uselocation)
  // This allows the component to check the route any time the user uses a link to navigate.
  const location = useLocation();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark background-clear sticky-top">
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img
              src="../astrotxt.png"
              className="d-inline-block align-top astrologo"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="row nav-item dropdown mx-1">
                <button
                  className="btn nav-link dropdown-toggle purple "
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  OUR SERVICES
                </button>
                <ul
                  className="dropdown-menu background-drop"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      to="/graphicdesign"
                      className={
                        location.pathname === "/graphicdesign"
                          ? "nav-link "
                          : "nav-link "
                      }
                    >
                      GRAPHIC DESIGN
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/website"
                      className={
                        location.pathname === "/website"
                          ? "nav-link "
                          : "nav-link "
                      }
                    >
                      WEB DEVELOPMENT
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/socialmedia"
                      className={
                        location.pathname === "/socialmedia"
                          ? "nav-link "
                          : "nav-link "
                      }
                    >
                      SOCIAL MEDIA MARKETING
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/photo"
                      className={
                        location.pathname === "/photo"
                          ? "nav-link "
                          : "nav-link "
                      }
                    >
                      PHOTO + VIDEO
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to="/getstarted"
                  aria-current="page"
                  className={
                    location.pathname === "/getstarted"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  GET STARTED
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  aria-current="page"
                  className={
                    location.pathname === "/contact"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  CONNECT
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/about"
                  aria-current="page"
                  className={
                    location.pathname === "/about"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  ABOUT US
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav">
            <li className="nav-item">
                <Link
                  to="/getstarted"
                  aria-current="page"
                  className={
                    location.pathname === "/getstarted"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  Sign-Up
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/client/login"
                  aria-current="page"
                  className={
                    location.pathname === "/client/login"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Toast/>
    </>
  );
}

export default NavTabs;
