import React from 'react';
import { Parallax, Background} from 'react-parallax';
import NavTabs from '../NavTabs';

const Contact = () => {
    return (
        <>
        <NavTabs/>
        <Parallax strength={300} className="outerParallax">
          <Background className="parallax">
              <img src="../home-bg-2.png"/>
          </Background>
              <ul className="text-center justify-content-center ul-icon-contact">
                  <a href="https://www.instagram.com/astronetdigital/?igshid=1ri7bxnnjye7j"><img src="./Icons/insta.png" className="icon"/></a>
                  <a href="mailto:team@astronetdigital.com"><img src="./Icons/email.png" className="icon"/></a>
                  <a href="https://twitter.com/astronetdigital?s=11"><img src="./Icons/twitt.png" className="icon"/></a>
                  <a href="https://m.facebook.com/ASTRONETDigital/?refsrc=https%3A%2F%2Fm.facebook.com%2Flogin%2Fdevice-based%2Fpassword%2F&_rdr"><img src="./Icons/fb.png" className="icon"/></a>
                  <a href="https://linkedin.com/astronet-digital"><img src="./Icons/in.png" className="icon"/></a>
              </ul>
        </Parallax>
        </>
    );
};

export default Contact;