import React from 'react';

function WhyAstro(props) {
    return (
        <div>
            <h1 className="text-center orange pt-3">Why ASTRONET?</h1>
            <h5 className="text-center text-light pb-3">When it comes to your Business, don't settle for BORING.</h5>
            <div className="row justify-content-evenly py-3 my-3">
                <img src="./Icons/1.png" className="example" alt="example"/>
                <img src="./Icons/2.png" className="example" alt="example"/>
                <img src="./Icons/3.png" className="example" alt="example"/>
            </div>
            <h5 className="text-center text-light pt-3">Unlike most run-of-the-mill Digital Agencies, at ASTRONET we ONLY produce fully custom Websites, Graphics, and Ads. We don't believe in templates, we strive to build tailored solutions to your problems, because like US, your company is not average either. </h5>
            <div className="row justify-content-center py-3">
            <a className="startedBtn" href="/getstarted">GET STARTED</a>
            </div>
        </div>
    );
}

export default WhyAstro;