import React, { useState, useContext, useEffect } from 'react';
import axios from "axios"
import {useHistory, useLocation} from "react-router-dom"
import AlertContext from '../../utils/alertContext';
import ClientTokenContext from '../../utils/clientTokenContext';
import NavTabs from '../NavTabs';


const NextStep = ({userLogin}) => {
    const [formObject, setFormObject] = useState({});
    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const location = useLocation();
    const history = useHistory()
    const alert = useContext(AlertContext);

    useEffect(() => {
        setFormObject(location.state.formObject)
        alert.setAlert({
            message: ""
        })
    },[setFormObject]);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        
            if (password1 !== password2) {
                alert.setAlert({
                    message: "PASSWORDS MUST MATCH!",
                    type: "danger",
                  });
            } else if (!email || !password1 || !password2) {
                alert.setAlert({
                    message: "FILL ALL INPUTS",
                    type: "danger",
                  });
            } else {
                axios
                        .post("/client/", { firstName: formObject.firstName, lastName: formObject.lastName, email: email, password: password2, phone: formObject.phone,
                            bizName: formObject.bizName})
                        .then((response) => {
                            console.log(response.data)
                            history.push({
                                pathname: "/response",
                            })
                        })
                        .catch((err) => {
                            console.log(err);
                            alert.setAlert({
                                message: "THERE IS ALREADY AN ACCOUNT ASSOCIATED WITH THIS EMAIL, LOGIN or USE A DIFFERENT EMAIL",
                                type: "danger",
                              });
                        }); 
                }
      };

    return (
    <>
    <NavTabs/>
    <form className="newUser" onSubmit={handleFormSubmit}>
        <div className="container">
            <div className="row formRow">
                <h1 className="text-center">Create Client Account</h1>
            </div>
                <div className="row formRow">
                    <div className="col-9">
                        <label htmlFor="email">Confirm Account Email</label>
                    </div>
                    
                        <input
                          id="email"
                          type="email"
                          className="astroForm mb-3"
                          placeholder={formObject.email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                    
                </div>
                <div className="row formRow">
                    <div className="col-sm-9">
                        <label htmlFor="password1">Password</label>
                    </div>
                    
                        <input
                          id="password1"
                          type="password"
                          className="astroForm mb-3"
                          onChange={(e) => {
                            setPassword1(e.target.value);
                          }}
                        />
                   
                </div>
                <div className="row formRow">
                    <div className="col-sm-9">
                        <label htmlFor="password2">Confirm Password</label>
                    </div>
                   
                        <input
                          id="password2"
                          type="password"
                          className="astroForm mb-3"
                          onChange={(e) => {
                            setPassword2(e.target.value);
                          }}
                        />
                  
                </div>
                <h3 className="text-center alertContent">{alert.message}</h3>
                <div className="row justify-content-center my-3 py-3">
                    <button
                        className="btn sub-btn"
                        type="submit"
                        name="action" >
                        Create User
                    </button>
                </div>
            </div>
        </form>
    </>
    );
};

export default NextStep;