import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const ExRow = ({ url,  _id, category, handleDelete}) => {


    
    return (
        <tr  key={_id}>
                    <td><img
                        src={url}
                        alt={_id}
                        style={{ height: "4em" }}
                      ></img>
                    </td>
                    <td>{category}</td>
                    <td  className="menuIcon"><FontAwesomeIcon icon={faTrash}  onClick={() => handleDelete(_id)} /></td>
                  </tr>
    );
};

export default ExRow;