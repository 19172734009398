import React from 'react';
import NavTabs from '../NavTabs';
import { Parallax, Background } from 'react-parallax';
import {useHistory} from 'react-router-dom';

function Response(props) {
    const history = useHistory();

    const btnClick = () => {
        history.push("/")
    }
    return (
        <>
        <NavTabs/>
        
        <Parallax strength={300} className="outerParallax">
          <Background className="parallax">
              <img src="../home-bg-2.png" alt="fill murray" />
          </Background>
          <ul className="astroLogo-ul">
            {/* <li className="text-center">
              <img src="../astronet-logo-new.png" className="homeLogo"/>
            </li> */}
            <li className="text-center">
                <p className="response-text">Thank you for your response! Someone on the Team will respond to you as soon as possible. In the mean time you can sign in with the email and password you just set to Respond Again, View Content we Create for YOU, and Pay for any Services.</p>
            </li>
            <li className="text-center">
                <btn className="response-btn" onClick={(e) => btnClick()}>Finish</btn>
            </li>
          </ul>
        </Parallax>
     
        </>
    );
}

export default Response;