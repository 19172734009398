import React from 'react';
import './iphone.css';

const IPhone = (props) => {
    return (
        <>
        <div className="col-md-6 mobile-ui">
            <iframe src={props.src} className="mobile-iframe"/>
            <img src="../Web/iphone.png" className="mobile-iphone"/>
        </div>
        </>
    );
}

export default IPhone;