import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from './CardInput';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});

function PayBox(props) {
  const history = useHistory()
  const classes = useStyles();
  // State
  const [email, setEmail] = useState('');
  const [thisClient, setThisClient] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setThisClient(thisClient);
    setEmail(props.email);
  }, [setEmail])

  const handleSubmitPay = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const res = await axios.post('/api/pay', {email: email, moneyOwed: props.moneyOwed});

    const clientSecret = res.data['client_secret'];

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Money is in the bank!');

        const fixMoneyOwed = () => {
             axios.put('/client/clients/' + props._id, {
                _id: props._id,
                email: props.email,
                phone: props.phone,
                bizName: props.bizName,
                firstName: props.firstName,
                lastName: props.lastName,
                iFrame: props.iFrame,
                moneyOwed: 0,
                subscription: props.sub
            }).then((response) =>{
                console.log(response.data);
                history.go(0);
            }).catch((error) => {
                console.log(error);
            }) 
        }
        fixMoneyOwed();
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };


  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
          <div className="row text-center">
              <p>Invoice Amount</p>
              <h1>{`$${Number((props.moneyOwed / 100).toFixed(2))}`}</h1>
          </div>
        <h5 className="text-center pt-3">{props.email}</h5>
        <p className="text-center pb-3">email you'll receive updates and receipts on</p>
        <CardInput />
        <div className={classes.div}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitPay}>
            Pay
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default PayBox;