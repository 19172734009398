import React from "react";
import API from "../../../utils/API";
import ExForm from "./ExForm/ExForm";
import { useHistory } from 'react-router-dom';
const NewEx = (props) => {
  const history = useHistory();

  const handleFormSubmit = (
    e,
    { url, category, description }
  ) => {
    e.preventDefault();

    API.createEx({  url, category, description }).then(
      (response) => {
        history.go(0);
      }
    );
  };

  return (
    <>
      <div className="container">
        <div className="row adminMenu">
          <div className="row">
            <div className="col s12">
              <h3 className="center-align formHead">Add a Picture</h3>
            </div>
          </div>
          <ExForm
            handleFormSubmit={handleFormSubmit}
            buttonText="Create New Item"
          />
        </div>
      </div>
    </>
  );
};

export default NewEx;
