import React, { useEffect, useState } from 'react';
import API from "../../utils/API";
import AdminNav from '../AdminNav';
import "./admin.css";

function Admin() {

    const [responses, setResponses] = useState([])

    useEffect(()=>{
        loadResponses()
    }, [])

    function loadResponses() {
        API.getResponses()
          .then(res => 
            setResponses(res.data)
          )
          .catch(err => console.log(err));
      };

      function deleteResponse(id) {
        API.deleteResponse(id)
          .then(res => loadResponses())
          .catch(err => console.log(err));
      }

    return (
      <div className="adminResponse">
      <AdminNav/>
        <div className="row">
          <h1 className="pt-3 mt-3 text-center purple">RESPONSES</h1>
        </div>
        <div className="responseTable">
        <table className="table scrollTable">
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Company</th>
                    <th scope="col">What it does</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Requests</th>
                    <th scope="col">Budget</th>
                    <th scope="col">Date</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
            {responses.map(response => {
                  return (
                    <tr key={response._id}>
                        <td>{response.firstName} {response.lastName}</td>
                        <td> 
                          <a href={"mailto:" + response.email}> {response.email} </a> 
                      </td>
                        <td>{response.phone}</td>
                        <td>{response.bizName}</td>
                        <td>{response.bizDo}</td>
                        <td>{response.bizKnow}</td>
                        <td>
                          {response.gd}  {response.wd}  {response.sm}  {response.ad} 
                        </td>
                        <td>{response.budget}</td>
                        <td>{response.date}</td>
                        <td>
                          <button id="delete" className="btn btn-danger mb-3" onClick={() => deleteResponse(response._id)}>Delete</button>
                        </td> 
                    </tr>
                  );
                })}
                </tbody>
            </table>
        </div>
        </div>
    );
}

export default Admin;