import React from 'react';

function SocialLanding(props) {
    return (
        <div>
            <h1 className="bigtxt1">SOCIAL MEDIA.</h1>
                    <h1 className="landsubtxt1">CREATIVE SOCIAL MEDIA MANAGEMENT</h1>
                    <p className="landsubtxt2">Let us Create/Manage your Social Media Pages! We specialize in creating visually appealing 
                    <br/> Social Media pages, and update them periodically with meaningful posts about your business.   
                    <br/> Allow our custom-tailored Graphics to catch the eyes of your target market, in order to 
                    <br/> visually appeal to the most possible potential customers/clients!  </p>
                    <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
        </div>
    );
}

export default SocialLanding;