import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from './CardInput';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});

function MPayment(props) {
  const history = useHistory()
  const classes = useStyles();
  // State
  const [email, setEmail] = useState('');
  const [thisClient, setThisClient] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setThisClient(thisClient);
    setEmail(props.email);
  }, [setEmail])


  const handleSubmitSub = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      const res = await axios.post('/api/sub', {'payment_method': result.paymentMethod.id, 'email': email, 'plan': props.subscription});
      // eslint-disable-next-line camelcase
      const {client_secret, status} = res.data;

      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function(result) {
          if (result.error) {
            console.log('There was an issue!');
            console.log(result.error);
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            console.log('You got the money!');
            // Show a success message to your customer
          }
        });
      } else {
        console.log('You got the money!');
        
        const fixMoneyOwed = () => {
            axios.put('/client/clients/' + props._id, {
               _id: props._id,
               email: props.email,
               phone: props.phone,
               bizName: props.bizName,
               firstName: props.firstName,
               lastName: props.lastName,
               iFrame: props.iFrame,
               moneyOwed: props.moneyOwed,
               subscription: props.sub,
               subFilled: true
           }).then((response) =>{
               console.log(response.data);
               history.go(0);
           }).catch((error) => {
               console.log(error);
           }) 
       }
       fixMoneyOwed();
       console.log(props.subFilled);
        // No additional information was needed
        // Show a success message to your customer
      }
    }
  };

if (props.subFilled == false) {return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {/* <TextField
          label='Email'
          id='outlined-email-input'
          helperText={`Email you'll recive updates and receipts on`}
          margin='normal'
          variant='outlined'
          type='email'
          required
          value={email}
          fullWidth
        /> */}
        <h1 className="text-center">Subscription</h1>
        <h3 className="text-center pt-3">{props.email}</h3>
        <p className="text-center pb-3">email you'll receive updates and receipts on</p>
        <CardInput />
        <div className={classes.div}>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitSub}>
            Subscription
          </Button>
        </div>
      </CardContent>
    </Card>
  )} else { return (
      <>
         <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <div className="text-center">
                        <h3>Subscription Active!</h3>
                    </div>
                </CardContent>
            </Card>
      </>
  )};
}

export default MPayment;