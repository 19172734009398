import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import AdminNav from '../AdminNav';



function ThisClientManager(props) {
    const [thisClient, setThisClient] = useState({});
    const [iFrame, setIframe] = useState(thisClient.iFrame);
    const [money, setMoney] = useState(thisClient.moneyOwed);
    const [sub, setSub] = useState(thisClient.subscription);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setThisClient(location.state.foundUser);
    }, [setThisClient]);

    const updateClient = (e) => {
        e.preventDefault();
        console.log(thisClient);
        console.log(iFrame);
        console.log(money);
        console.log(sub);
        axios.put('/client/clients/' + thisClient._id, {
            _id: thisClient._id,
            email: thisClient.email,
            phone: thisClient.phone,
            bizName: thisClient.bizName,
            firstName: thisClient.firstName,
            lastName: thisClient.lastName,
            iFrame: iFrame,
            moneyOwed: money,
            subscription: sub
        }).then((response) =>{
            console.log(response.data);
            history.push({
                pathname: '/admin/manager',
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
        <AdminNav/>
            <div className="container">
                <div className="row clientCard">
                <form className="" onSubmit={updateClient}>
                    <div className="row text-center">
                        <h1>{thisClient.bizName}</h1>
                    </div>
                    <div className="row text-center">
                        <h3>{thisClient.firstName} {thisClient.lastName}</h3>
                    </div>
                    <div className="row text-center">
                        <a href={thisClient.email}>{thisClient.email}</a>
                    </div>
                    <div className="row text-center">
                        <a href={thisClient.phone}>{thisClient.phone}</a>
                    </div>
                    <div className="row text-center">
                        <div className="col-2"/>
                        <div className="col-3">
                             <p>iFrame</p>
                        </div>
                        <div className="col-4">
                            <input type="text" name="iFrame" placeholder={thisClient.iFrame} onChange={(e) => {setIframe(e.target.value)}}/>
                        </div>
                        <div className="col-2"/>
                    </div>
                    <div className="row text-center">
                        <div className="col-2"/>
                        <div className="col-3">
                             <p>$ Owed</p>
                        </div>
                        <div className="col-4">
                            <input type="number" name="moneyOwed" placeholder={thisClient.moneyOwed} onChange={(e) => {setMoney(e.target.value)}}/>
                        </div>
                        <div className="col-2"/>
                    </div>
                    <div className="row text-center">
                        <div className="col-2"/>
                        <div className="col-3">
                             <p>Subscription</p>
                        </div>
                        <div className="col-4">
                            <input type="text" name="sub" placeholder={thisClient.subscription} onChange={(e) => {setSub(e.target.value)}}/>
                        </div>
                        <div className="col-2">
                            <p>{thisClient.subFilled}</p>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-4"/>
                        <div className="col-4">
                            <button type="submit" className="btn btn-light" onClick={(e) => updateClient(e)}>UPDATE</button>
                        </div>
                        <div className="col-4"/>
                    </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ThisClientManager;