import React from 'react';
import './qa.css'; 
import $ from 'jquery';

const QA = (props) => {

    const openAnswer = (e) => {
        
        $('.answer').removeClass('show-answer');
        $('.question').removeClass('show-question');
        $('.question').removeClass('next-question');
        $(`.answer-${e.target.value}`).addClass('show-answer');
        $(`.question-${e.target.value}`).addClass('show-question');
        const integer = parseInt(e.target.value, 10);
        const nextBlock = integer + 1;
        $(`.question-${nextBlock}`).addClass('next-question');
        console.log(nextBlock)
    }
    return (
        <div>
            <div className="container q-a">
                <div className="row text-left">
                    <h1 className="q-a-title">Q&A</h1>
                </div>
                <div className="row text-left question question-1">
                    <div className="row">
                        <div className="col-10">
                            <p className="q-text">{props.one}</p>
                        </div>
                        <div className="col-2">
                            <button className="answer-btn" value="1" onClick={(e) => openAnswer(e)}>
                                +
                            </button>
                        </div>
                    </div>
                    <div className="row text-left answer answer-1">
                    {props.oneA}
                    </div>
                    
                </div>
                <div className="row text-left question question-2">
                    <div className="row">
                        <div className="col-10">
                            <p className="q-text">{props.two}</p>
                        </div>
                        <div className="col-2">
                            <button className="answer-btn" value="2" onClick={(e) => openAnswer(e)}>+</button>
                        </div>
                    </div>
                    <div className="row text-left answer answer-2">
                    {props.twoA}
                    </div>
                    </div>
                    <div className="row text-left question question-3">
                    <div className="row">
                        <div className="col-10">
                            <p className="q-text">{props.three}</p>
                        </div>
                        <div className="col-2">
                            <button className="answer-btn" value="3" onClick={(e) => openAnswer(e)}>+</button>
                        </div>
                    </div>
                    <div className="row text-left answer answer-3">
                    {props.threeA}
                    </div>
                    </div>
                    <div className="row text-left question question-4">
                    <div className="row">
                        <div className="col-10">
                            <p className="q-text">{props.four}</p>
                        </div>
                        <div className="col-2">
                            <button className="answer-btn" value="4" onClick={(e) => openAnswer(e)}>+</button>
                        </div>
                    </div>
                    <div className="row text-left answer answer-4">
                    {props.fourA}
                    </div>
                    </div>
                    <div className="row text-left question question-5">
                    <div className="row">
                        <div className="col-10">
                            <p className="q-text">{props.five}</p>
                        </div>
                        <div className="col-2">
                            <button className="answer-btn" value="5" onClick={(e) => openAnswer(e)}>+</button>
                        </div>
                    </div>
                    <div className="row text-left answer answer-5">
                    {props.fiveA}
                    </div>
                    </div>
                    <div className="row text-left question question-6">
                    <div className="row">
                        <div className="col-10">
                            <p className="q-text">{props.six}</p>
                        </div>
                        <div className="col-2">
                            <button className="answer-btn" value="6" onClick={(e) => openAnswer(e)}>+</button>
                        </div>
                    </div>
                    <div className="row text-left answer answer-6">
                    {props.sixA}
                    </div>
                    </div>
            </div>
        </div>
    );
}

export default QA;