import React, {useEffect, useContext, useState} from 'react';
import "./toast.css";
import $ from "jquery";
import ToastContext from "../toastContext"; 
import axios from 'axios';

const Toast = () => {
  const [toastState, setToastState] = useState("");
  const [loading, setLoading] = useState(true)
  const Toast = useContext(ToastContext);

  const closeToast = () => {
    $(".toast").addClass("closeThis");
       setToastState(true);
       Toast.setContinue();
       axios.get('/api/auth/policy/true').then((response) => {console.log(response)})
    .catch((err) => { if(err) {console.log(err)}});
  }

  useEffect(()=> {
    axios.get('/api/auth/policy').then((response) => {
      console.log(response.data.continue)
      if (response.data.continue === "true") {
        $(".toast").addClass("closeThis properly");
      } else {
        console.log("Accept Cookie Policy")
      }
    })
      setToastState("true");
      setLoading(false);
  }, [setLoading])
  return (
    <>
    <div className="sticky-bottom">
      <div className="toast cookieToast position-absolute w-100 d-flex flex-column p-4">
        <div className="toast-header">
          <div className="col-3"/>
          <div className="col-6 toast-text ">
            <p>By continuing to browse on our Website you agree to the use of Cookies to aid with performance and security.</p>
          </div>
          <div className="col-3 text-center">
            <button type="button" className="btn btn-accept close" onClick={() => closeToast()}>
              <span aria-hidden="true">CONTINUE</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    
    </>
  );
}

export default Toast;