import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import {useLocation, useHistory} from "react-router-dom";
import AlertContext from "../../utils/alertContext";
import API from "../../utils/API";
import ClientTokenContext from "../../utils/clientTokenContext";

function ClientForm() {
    const [formObject, setFormObject] = useState({})
    // const location = useLocation();
    const {clientEmail} = useContext(ClientTokenContext);
    // const history = useHistory();
  
    const [thisClient, setThisClient] = useState("");
   
    useEffect(() => {
        axios.get("/api/response/email/" + clientEmail)
        .then(response => {
            console.log(response.data);
            setThisClient(response.data[0]);
        });
        
    }, [setThisClient]);

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormObject({...formObject, [name]: value})
      };

      const alert = useContext(AlertContext);
      const history = useHistory();
    

      function handleFormSubmit(event) {
          event.preventDefault();
          API.createClientResponse({
            firstName: thisClient.firstName,
            lastName: thisClient.lastName,
            email: thisClient.email,
            phone: thisClient.phone,
            bizName: thisClient.bizName,
            gd: formObject.gd,
            wd: formObject.wd,
            sm: formObject.sm,
            ad: formObject.ad,
            budget: formObject.budget
          })
            .then((response) => {
                history.push({
                    pathname: '/client/home'
                })
            })
            .catch((err) => {
                console.log(err);
                if (formObject.firstName == null || formObject.lastName == null || formObject.email == null) {
                    alert.setAlert({
                        message: "Please enter your name and Email!",
                        type: "danger",
                     });
                } else {
                    alert.setAlert({
                        message: "THERE IS ALREADY AN ACCOUNT ASSOCIATED WITH THIS EMAIL, LOGIN or USE A DIFFERENT EMAIL",
                        type: "danger",
                    });
                }
              });
        
      };

    return (
        <>
      <div className="home-form">
          <div className="row form">
          <div className="row">
              <h3 className="text-center">REQUEST ADDITIONAL SERVICES</h3>
          </div>
          <div className="px-3">
        <form onSubmit={(e) => {
          handleFormSubmit(e);
        }}> 
            
            <div className="row">
            <label htmlFor="bizKnow" className="col-form-label purple">Check all Services Needed:</label>
            <div className="row mb-3">
                <div className="col-sm-12 checkServices">
                <div className="form-check">
                    <input className="form-check-input astroCheck" type="checkbox" id="GraphicDesign" value="Needs Graphic Design" name="gd" onChange={(e)=>handleInputChange(e)}/>
                    <label className="form-check-label purple" htmlFor="GraphicDesign">
                    Graphic Design
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input astroCheck" type="checkbox" id="WebDev" value="Needs Web Dev" name="wd" onChange={(e)=>handleInputChange(e)}/>
                    <label className="form-check-label purple" htmlFor="WebDev">
                    Web Development
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input astroCheck" type="checkbox" id="smedia" value="Needs Social Media" name="sm" onChange={(e)=>handleInputChange(e)}/>
                    <label className="form-check-label purple" htmlFor="smedia">
                    Social Media
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input astroCheck" type="checkbox" id="Ads" value="Needs Ads" name="ad" onChange={(e)=>handleInputChange(e)}/>
                    <label className="form-check-label purple" htmlFor="Ads">
                    Advertisement
                    </label>
                </div>
                </div>
            </div>
            <div class="row mb-3">
                <label className="col-form-label purple" htmlFor="budget">
                        Monthly Budget
                </label>
                <select className="form-select astroChoose" id="budget" name="budget" onChange={(e)=>handleInputChange(e)}>
                    <option defaultValue>Choose...</option>
                    <option value="0-100">0-100</option>
                    <option value="100-500">100-500</option>
                    <option value="500-1,000">500-1,000</option>
                    <option value="1,000-10,000">1,000-10,000</option>
                </select>
                <div className=" my-3">
                <label htmlFor="bizKnow" className="col-form-label purple">Specifically, what does your business desire from us?</label>
                <div className="row">
                <input type="text" className="form-control astroForm" id="bizKnow" name="bizKnow" onChange={(e)=>handleInputChange(e)}/>
                </div>
            </div>
            </div>
            <p className="text-center">{alert.message}</p>
             <div className="row">
                <button type="submit" className="btn sub-btn">Continue</button>
            </div> 
            </div>
        </form>
      </div>
      <div className="col-sm-2"/>
      </div>
      </div>
      </>
    );
  };
  
  export default ClientForm;