import React, {useState, useEffect, useContext} from 'react';
import ClientNav from '../ClientNav';
import {useHistory, useLocation} from 'react-router-dom';
import axios from 'axios';
import ClientTokenContext from '../../utils/clientTokenContext.js'

function ViewCreations(props) {
    const {clientEmail} = useContext(ClientTokenContext);
    const [thisClient, setThisClient] = useState("");
    const history = useHistory();
    const location = useLocation();

  useEffect(() => {
      axios.get("/api/response/email/" + clientEmail)
      .then(response => {
          setThisClient(response.data[0]);
      });
      console.log(thisClient);
  }, [setThisClient]);

    return (
        <div>
            <ClientNav/>
            <iframe src={thisClient.iFrame} className="clientIframe"/>
        </div>
    );
}

export default ViewCreations;