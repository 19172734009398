import React from 'react';
import SkillCard from './SkillCard';
import SkillCardRight from './SkillCardRight';

function Services(props) {
    return (
        <>
            <div className="row">
            <h1 className="service-title-text">WHAT DO WE DO? </h1>
        </div>
         <div className="">
            <div className="row skill-card">
                <SkillCardRight 
                    title="Web Development"
                    text="Our talented team of web developers create full-stack web applications tailored to each client. Depending on your needs, applications can include different functionalities such as: Animation, Databases, Contact Boxes, Ability to login as an admin on your app (with ability to update content), Scheduling Solutions, eCommerce, and more. Already have a site? No problem, our team also specializes in website updating and revamping as well."
                    href="/website"
                    src="./Cards/webdev-card.png"
                />
            </div>
            <div className="row skill-card">
                <SkillCard
                title="Graphic Design"
                text="Nothing grabs the attention of your target market better than crisp, creative, and custom graphics. Therefore we only create 1 of 1 Logos, Animations, Banners, Infographics, Social Media posts, and advertisements. Our custom-tailored Graphics will catch the eyes of your target market, in order to appeal to the highest amount of potential costumers. Already have a Logo? No problem, we also edit and restyle graphics."
                    href="/graphicdesign"
                    src="./Cards/gfx-card.png"
                />
            </div>
            <div className="row skill-card">
                <SkillCardRight 
                title="Advertisement Strategy"
                text="Need Ads? Our team of Advertisement Specialists run targeted multi-platform advertisements, to boost costumer outreach, sales, and brand identity. Our goal is to use advertisements ran via Website or Social Media to scale your business to new heights. All advertisements we run are crafted by our talented team of Graphic Designers to visually appeal to your specific target market, to catch the eyes of the most possible costumers."
                    href="/socialmedia"
                    src="./Cards/ads-card.png"
                />
            </div>
            <div className="row skill-card">
                <SkillCard 
                title="Social Media Management"
                text="Our team of experienced Social Media Managers set-up accounts, create content/posts, and will run accounts on any desired platform, including but not limited to: Instagram, Facebook, Twitter, LinkedIn, Tik Tok, and Snapchat. With the combination of our Graphics and Advertisement Strategies, your social media will not only look far from average, but perform far from average as well. Social Media accounts can also funnel users to your website."
                    href="/socialmedia"
                    src="./Cards/sm-card.png"
                />
            </div>
            <div className="row skill-card">
                <SkillCardRight 
                title="Videography and Photography"
                text="To aid with Web Development, Advertisements, and Social Media content we offer in-house Photography and Videography services. We offer Promotion Videos, Drone Footage, Action Shots, Stills, Portraits and more. We also offer photo and video editing services as well; all content can be edited with graphics, such as: Custom Borders, Text Overlay, Logos, Watermarks, and more."
                    href="/photo"
                    src="./Cards/photography-card.png"
                />
            </div>
         </div>
        </>
    );
}

export default Services;