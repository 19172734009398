import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {Parallax} from 'react-parallax';
import NavTabs from '../../containers/NavTabs';
function Photography(props) {
    const [examples, setExamples] = useState([]);
    const [Examples1, setExamples1] = useState([]);
    const [Examples2, setExamples2] = useState([]);
    const [Examples3, setExamples3] = useState([]);
    const [Examples4, setExamples4] = useState([]);
    const featured = [];

    useEffect(() => {
        async function fetchData () {
            const response = await axios.get('/api/pictures/');
                    for(let i = 0; i < response.data.length; i++) {
                        if (response.data[i].category === 'Picture') {
                            featured.push(response.data[i])
                        } else {}
                        
                    };
                    const half = Math.ceil(featured.length / 2);    
                    const firstHalf = featured.splice(0, half);
                    const secondHalf = featured.splice(-half);

                    const halfAgain = Math.ceil(firstHalf.length / 2);    
                    const array1 = firstHalf.splice(0, halfAgain);
                    const array2 = firstHalf.splice(-halfAgain);

                    const half3 = Math.ceil(secondHalf.length / 2);    
                    const array3 = secondHalf.splice(0, half3);
                    const array4 = secondHalf.splice(-half3);

                    setExamples1(array1);
                    setExamples2(array2);
                    setExamples3(array3);
                    setExamples4(array4);
                    setExamples(featured);
        }
        fetchData();
    }, [setExamples]);

    return (
        <>
            <div className="picture-page">
                <Parallax blur={10} bgImage="./home-bg-2.png" 
                    bgImageAlt="the cat" strength={300} className="parallax-social">
                    
                    <h2 className="pictures-logo-text text-center pt-3">Some work we've done</h2>
                

                    <div className="text-center pics-container row">
                        <div className="column">
                            {Examples1.map((response) => (
                                <img className="page-img" alt="essay" src={response.url}/> 
                            ))}
                        </div>
                        <div className="column">
                            {Examples2.map((response) => (
                                <img className="page-img" alt="essay" src={response.url}/> 
                            ))}
                        </div>
                        <div className="column">
                            {Examples3.map((response) => (
                                <img className="page-img" alt="essay" src={response.url}/> 
                            ))}
                        </div>
                        <div className="column">
                            {Examples4.map((response) => (
                                <img className="page-img" alt="essay" src={response.url}/> 
                            ))}
                        </div>

                
                    </div> 
                </Parallax>
            </div>
        </>
    );
}

export default Photography;