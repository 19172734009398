import React, { useState, useContext } from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import AlertContext from "../../utils/alertContext";
import jwt from "jsonwebtoken";
import NavTabs from "../NavTabs";

const Login = ({login}) => {
    
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const alert = useContext(AlertContext);
  const history = useHistory();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/auth/login", { email, password })
      .then((response) => {
        console.log(response.data);
        jwt.verify(
          response.data.token,
          process.env.REACT_APP_JWT_SIGNATURE,
          (err, decoded) => {
            if (err) {
              console.log(err);
            } else {
              login(response.data.token, () => {
                history.push("/admin");
              });
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        alert.setAlert({
          message: "Incorrect Username or Password please try again!",
          type: "danger",
        });
      });
  };
  return (
    <>
    <NavTabs/>
    <div className="container login-block">
    <p className="text-center">{alert.message}</p>
    <form onSubmit={(e) => {
          handleFormSubmit(e);
        }}> 
            <div className="row login-input py-3 my-3">
                <label htmlFor="name" className="col-form-label purple">Email</label>
                <div className="row">
                <input type="email" className="form-control astroForm" id="email" name="email" value={email} onChange={(e) => {setEmail(e.target.value);}}/>
                </div>
            </div>
            <div className="row login-input mb-3">
                <label htmlFor="email" className="col-form-label purple">Password</label>
                <div className="row">
                <input type="password" className="form-control astroForm" id="password" name="password" value={password} onChange={(e) => {setPassword(e.target.value);}}/>
                </div>
            </div>
            <div className="row">
                <button type="submit" className="btn sub-btn">Login</button>
            </div>
        </form>
    </div>
    </>
  );
};

export default Login;
