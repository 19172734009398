import React, {useContext, useEffect, useState} from 'react';
import ClientNav from '../ClientNav';
import axios from "axios";
import ClientTokenContext from '../../utils/clientTokenContext';

function Account(props) {

    const {clientEmail} = useContext(ClientTokenContext);
    const [thisClient, setThisClient] = useState("");

    useEffect(() => {
        axios.get("/api/response/email/" + clientEmail)
        .then(response => {
            console.log(response.data);
            setThisClient(response.data[0]);
        });
        
    }, [setThisClient]);

    const updatePassword = () => {

    }

    return (
        <div>
            <ClientNav/>
            <table className="table scrollTable account-viewer">
              <thead>
                  <tr>
                  <th scope="col"></th>
                  <th scope="col">ACCOUNT INFO</th>
                  <th></th>
                  </tr>
              </thead>
              <tbody>
              <tr key={thisClient.email}>
                      <td>Email:</td>
                      <td> 
                        <a href={"mailto:" + thisClient.email}> {thisClient.email} </a> 
                      </td>
                  </tr>
                  <tr key={thisClient.firstName}>
                      <td>First Name:</td>
                      <td>{thisClient.firstName}</td>  
                  </tr>
                  <tr key={thisClient.lastName}>
                      <td>Last Name:</td>
                      <td>{thisClient.lastName}</td>
                  </tr>
                  <tr key={thisClient.bizName}>
                      <td>Company:</td>
                      <td> {thisClient.bizName}</td>
                  </tr>
                  <tr key={thisClient.email}>
                      <td>PASSWORD:</td>
                      <td> </td>
                      <td><button className="sub-btn" onClick={updatePassword}>RESET</button> </td>
                  </tr>
              </tbody>
          </table>
      </div>
        
    );
}

export default Account;