import React from 'react';

function GraphicLanding(props) {
    return (
        <div>
               <h1 className="bigtxt1">GRAPHICS.</h1>
                    <h1 className="landsubtxt1">ATLANTA'S #1 GRAPHIC DESIGNER</h1>
                    <p className="landsubtxt2">AstroNET Digital creates 1 of 1 graphics. We specialize in Logos, Banners, Infographics,
                    <br/> Social Media posts, and advertisements. Our custom-tailored Graphics will catch the eyes of 
                    <br/> your target market, in order to visually appeal the most possible potential customers/clients!  </p>
                    <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
        </div>
    );
}

export default GraphicLanding;