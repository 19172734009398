import React from 'react';
import { Parallax, Background } from 'react-parallax';
import ServiceRow from '../../components/GraphicDesign/ServiceRow';
import Photography from '../../components/Photography/Photography';
import QA from '../../components/Website/QA';
import Scenarios from '../../components/Website/Scenarios';
import NavTabs from '../NavTabs';
import  $  from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faAddressCard, faTabletAlt, faChartLine, faSignal, faCartPlus, faGift, faBuilding, faBriefcase, faUserTie, faGlasses  } from "@fortawesome/free-solid-svg-icons";

const Photo = () => {
    const Show1 = () => {
        $('.service-card-row-2').removeClass('show-graphic-cards');
        $('.service-card-row-1').addClass("show-graphic-cards");
    }

    const Show2 = () => {
        $('.service-card-row-1').removeClass('show-graphic-cards');
        $('.service-card-row-2').addClass("show-graphic-cards");
    }
    return (
      <>
        <NavTabs/>
      
        <div className="service-page">
        
            <Parallax bgImage="https://media2.govtech.com/images/940*617/social+media+apps1.jpg" 
        bgImageAlt="the cat" strength={-300} className="parallax-service">
              <ul className="text-center service-landing">
                  <li>
                      <h1 className="bigtxt1">PHOTO + VIDEO.</h1>
                  </li>
                  <li>
                      <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                  </li>
              </ul>
              
                    

        </Parallax>
     </div>
     <Scenarios
        one="I want to update my website with updated, high quality photos or videos."
        two="I need content to post on social media."
        three="I have photos of my business, but need a promotional video."
        four="My brands current content is out of date."
        five="My brands current content is not high quality."
        six="I have good content for my brand already but want more."
     />
     <div className="background-blue-12"/>
     <div className="website-content">
     <div className="social-graphic-outside text-center text-light">
             <div className="row text-center">
             
                 <h1 className="social-graphic-title">How does Photography and Videography help businesses?</h1>
             </div>
             <div className="row facts-row">
                 <div className="col-3">
                    <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faAddressCard} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                 
                <p className="social-graphic-text">Brands with professional and personalized photography gain an advantage over their competitors.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faTabletAlt} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faChartLine} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">Quality Photography and Videography keeps visitors on your site, websites with eye-popping images average over 90% more views. </p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faSignal} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faCartPlus} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">Research shows that more than half of the population is comprised of visual learners. Simpily put, people would rather see images or videos than just a plethora of text.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faGift} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faBuilding} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">People are more likely to remember the images or videos on your site or social media accounts than the words. </p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faUserTie} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faBriefcase} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">When Deciding on a Potential Purchase, 75% of Online Shoppers Rely on Product Photos.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faGlasses} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 
                 </div>
         </div>
     </div>
     <div className="background-blue-13"/>
     <div className="website-content-3">
         <Photography/>
     </div>
 
     
     {/* <div className="website-content-3">
     <div className="row service-cards-row">
            <div className="row">
                <button className="btn text-light graphic-service-btn" onClick={() => { Show1(); }}>PHOTOGRAPHY</button>
            </div>
        <ServiceRow 
        number="1"

        title1="Logo Design" title2="Brand Guides" title3="Stationeries"

        text11="Includes a stunning professionally designed logo."
        text12="Logo design targeted towards effectively establishing your brand's identity."
        text13="Fully-customizable with consistent client to designer feedback."

        text21="Includes a comprehensive guide of your brand's fonts, colors, and overall style."
        text22="Custom-tailored by our professional designers to your brand's own identity. "
        text23="Easily shareable format to ensure consistency among your brand's styling."

        text31="Complete your branding with gorgeous custom stationery that keeps you looking polished and professional."
        text32="Includes: letterhead, envelope, note, business card, or stickers, etc.."
        text33="No matter what you have designed, your stationery  will be uploaded in the correct formats based on your stated needs."

        price1="99+" price2="149+" price3="199+"
        />
        </div>
        <div className="row service-cards-row">
        <div className="row">
                <button className="btn text-light graphic-service-btn" onClick={() => { Show2(); }}>VIDEOGRAPHY</button>
        </div>
        <ServiceRow
        number="2"

        title1="Postcards / Flyers" title2="Product Packaging" title3="Infographics"

        text11="Make your brand look good while you spread the word with an attention-grabbing flyer design."
        text12="All of our designers are hand-vetted and rated for their quality, so you’ll know you’re working with the best."
        text13="Any style, any industry. Our flyer designers do it all."

        text21="Custom packaging design for your products that generate sales."
        text22="Eye-catching packaging will make your product stand out to consumers."
        text23="You’ll collaborate and give feedback to create the ideal product package."

        text31="Can’t decide whether you need info or graphics? Get the best of both worlds in an infographic."
        text32="Organize data, lists and all sorts of information your customers need in a fun and engaging way."
        text33="Bright illustrations and simple charts turn gobs of information into social-friendly content that your audience will love."

        price1="49+" price2="99+" price3="149+"
        />
     </div>
     </div> */}

     <div className="website-content-3">
     <QA
         
         one="What medium do we use?"
         oneA="We prefer to use Premiere Pro for our editing process. We shoot in 1080p 1920x1080 resolution.Google pixel and facebook ads manager"
         

         two="How will I receive content created for me?"
         twoA="Google Drive but we are flexible if a different software is used by your company. If a local company and established client we can personally deliver the files"

         three="How will I pay for service?"
         threeA="Payment will be accepted via this website! After creating an account, you can log in to pay for and view content we create for you (When the design is completed)."

         four="Are there bundle discounts for Photography and Videography?"
         fourA="Of course! Please request a quote or email us at team@astronetdigital.com"

         five="What will my business use the Photography and Videography for?"
         fiveA="We will have a consultation meeting to interview you and assess your business in order to identify which of our services, if not all, are a fit for your business. "

         six="How long will editing take?"
         sixA="Depending on the amount of footage that was taken during the shoot and/or the amount of content being worked on at once, the hours of editing may very."

         />
     </div>
      </>
    );
};

export default Photo;