import React from 'react';
import {Parallax, Background} from 'react-parallax';

function SkillCard(props) {
    return (
        <>
        {/* <div className="row">
            <div className="col card-image">
                <a href={props.href} >
                    <img src={props.src} className="graphic"/>
                </a>
            </div>
            <div className="card-btn">
                <a href={props.href} className="learn-more">LEARN MORE</a>
            </div>
        </div> */}
        <div className="col-md-6">
        <Parallax strength={300} className="outerParallax-left">
          <Background className="parallax-left">
          <img src={props.src} className="graphic-left"/>
          </Background>
          <ul className="astroLogo-ul">
            <li className="text-center">
            
            </li>
          </ul>
        </Parallax>
           
        </div>
        <div className="col-md-6 service-text-row">
            <div className="row text-center service-text-title">
                <h1>{props.title}</h1>
            </div>
            <div className="row text-center">
                <h5 className="service-text-p">{props.text}</h5>
            </div>
            <div className="row card-btn">
                <a href={props.href} className="learn-more">LEARN MORE</a>
            </div>
        </div>
        
        </>
    );
}

export default SkillCard;