import React from 'react';
import AdminNav from '../AdminNav';
import MyExamples from './Update/MyExamples';
import NewEx from './Update/NewEx';

function PortfolioManager(props) {
    return (
        <>
            <AdminNav/>
            <MyExamples/>
            <NewEx/>
        </>
    );
}

export default PortfolioManager;