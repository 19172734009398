import React from 'react';

const Scenarios = (props) => {
    return (
        <div className="website-content-2">
         <div className="row text-center mb-3 pb-3">
             <h1 className="scenario-title">Do any of the scenarios below sound familiar?</h1>
         </div>
         <div className="row text-center sc-row">
             <div className="col-sm-4 ">
                 <div className="sc-card">
                     {props.one}
                 </div>
             </div>
             <div className="col-sm-4">
                 <div className="sc-card">
                 {props.two}
                 </div>
             </div>
             <div className="col-sm-4">
                 <div className="sc-card">
                 {props.three}
                 </div>
             </div>
         </div>
         <div className="row text-center sc-row">
         <div className="col-sm-4">
                 <div className="sc-card">
                 {props.four}
                 </div>
             </div>
             <div className="col-sm-4">
                 <div className="sc-card">
                 {props.five}
                 </div>
             </div>
             <div className="col-sm-4">
                 <div className="sc-card">
                 {props.six}
                 </div>
             </div>
         </div>
         <div className="row text-center mt-3 pt-3">
             <h3 className="orange"> If any of these scenarios apply to you, let us help!</h3>
         </div>
         <div className="row text-center mt-3 pt-3">
             <ul className="justify-content-center">
                <li>
                    <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                </li>
            </ul>
         </div>
         
     </div>
    );
}

export default Scenarios;