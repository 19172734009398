import React from 'react';
import NavTabs from '../NavTabs';
import Form from './Form';

function GetStarted(props) {
    return (
        <div>
            <NavTabs/>
            <Form/>
        </div>
    );
}

export default GetStarted;