import React, {useContext} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import API from "../utils/API";
import TokenContext from "../utils/tokenContext";

function AdminNav() {
  // We'll go into the Hooks API later, for now, we are just using some code
  // from the react-router docs (https://reacttraining.com/react-router/web/api/Hooks/uselocation)
  // This allows the component to check the route any time the user uses a link to navigate.
  const location = useLocation();
  const {logout, token} = useContext(TokenContext);
  const history = useHistory();

  const logOutUser = () => {
    logout(() => {
      history.push("/");
      API.logoutAdmin();
    })
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light background-orange sticky-top">
        <div className="container-fluid">
          <a href="/admin" className="navbar-brand">
            <img
              src="../astronet-logo-new.png"
              className="d-inline-block align-top astrologo-admin"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <Link
                  to="/admin"
                  aria-current="page"
                  className={
                    location.pathname === "/admmin"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  RESPONSES
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/admin/manager"
                  aria-current="page"
                  className={
                    location.pathname === "/admin/manager"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  CLIENT MANAGER
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/admin/portfolio-manager"
                  aria-current="page"
                  className={
                    location.pathname === "/admin/portfolio-manager"
                      ? "nav-link orange"
                      : "nav-link purple"
                  }
                >
                  PORTFOLIO MANAGER
                </Link>
              </li>
              </ul>
            <ul className="navbar-nav">
            <li className="nav-item clientsName">
                ADMIN
            </li>
            <li className="nav-item">
            <button className="logoutBtn" onClick={()=>logOutUser()}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default AdminNav;
