import axios from "axios";

// eslint-disable-next-line
export default {
  // Gets all books
  getResponses: function() {
    return axios.get("/api/response");
  },
  // Gets the book with the given id
  getResponse: function(id) {
    return axios.get("/api/response/" + id);
  },
  // Deletes the book with the given id
  deleteResponse: function(id) {
    return axios.delete("/api/response/" + id);
  },
  // Saves a book to the database
  createResponse: function(responseData) {
    return axios.post("/api/response", responseData);
  },
  createClientResponse: function(responseData) {
    return axios.post("/api/response/client", responseData);
  },
  logoutUser: function(data){
    return axios.post("/client/logout", { }, { withCredentials: true});
  },
  logoutAdmin: function(data){
    return axios.post("/api/auth/logout", { }, { withCredentials: true});
  },
    getExamples: function() {
      return axios.get("/api/pictures/");
    },
    // Returns 1 b4 n after
    getEx: function(id) {
      return axios.get("/api/pictures/" + id);
    },
    // Deletes one set of B4 and After pics
    deleteEx: function(id) {
      return axios.delete("/api/pictures/" + id);
    },
    // Creates B4 and After set
    createEx: function(responseData) {
      return axios.post("/api/pictures", responseData);
    },
    // Updates Set
    updateEx: function(responseData, id) {
      return axios.put("/api/pictures/" + id, responseData);
    },
};
