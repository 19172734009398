import React from 'react';
import {Parallax, Background} from 'react-parallax';

function SkillCardRight(props) {
    return (
        <>
       
        <div className="col-md-6 service-text-row-right">
            <div className="row text-center service-text-title">
                <h1>{props.title}</h1>
            </div>
            <div className="row text-center">
                <h5 className="service-text-p">{props.text}</h5>
            </div>
            <div className="row card-btn">
                <a href={props.href} className="learn-more">LEARN MORE</a>
            </div>
        </div>
        <div className="col-md-6 order-first order-md-last">
        <Parallax strength={300} className="outerParallax-right">
          <Background className="parallax-right">
          <img src={props.src} className="graphic-right"/>
          </Background>
          <ul className="astroLogo-ul">
            <li className="text-center">
            
            </li>
          </ul>
        </Parallax>
           
        </div>
        </>
    );
}

export default SkillCardRight;