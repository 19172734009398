import axios from 'axios';
import React, {useEffect, useState} from 'react';
import API from '../../utils/API';
import AdminNav from '../AdminNav';
import {useHistory} from 'react-router-dom';

function ClientManager(props) {
    const [clients, setClients] = useState([]);
    const history = useHistory();

  useEffect(() => {
      const loadResponses = async() => {
            await axios.get("/client/clients")
            .then(response => {
                 setClients(response.data.foundUsers);
            });
         };
    loadResponses();
  }, [setClients]);

  const handleClick = (event) => {
    axios.get("/client/clients/" + event.target.value)
    .then(response => {
         console.log(response.data.foundUser)
         history.push({
          pathname: `/admin/manager/client`,
          state: {foundUser: response.data.foundUser}
      });
    });
    
  }

  

  return (
    <div className="adminResponse">
    <AdminNav/>
      <div className="row">
        <h1 className="pt-3 mt-3 text-center purple">CLIENTS</h1>
      </div>
      <div className="responseTable">
      <table className="table scrollTable">
              <thead>
                  <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Company</th>
                  <th scope="col"></th>
                  </tr>
              </thead>
              <tbody>
          {clients.map(response => {
                return (
                  <tr key={response._id}>
                      <td>{response.firstName} {response.lastName}</td>
                      <td> 
                        <a href={"mailto:" + response.email}> {response.email} </a> 
                    </td>
                      <td>{response.phone}</td>
                      <td>{response.bizName}</td>
                      <td>
                        <button className="btn btn-primary" key={response._id} value={response._id} onClick={(e) => handleClick(e)}>INFO</button>
                      </td>
                  </tr>
                );
              })}
              </tbody>
          </table>
      </div>
      </div>
  );
}

export default ClientManager;