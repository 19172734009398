import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../../utils/API";
import "./MenuForm.css";

const ExForm = ({ buttonText, handleFormSubmit }) => {
  const [url, setUrl] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();

  useEffect(() => {
    
    if (id) {
      API.getEx(id).then((response) => {
        setUrl(response.data.url);
        setCategory(response.data.category);
        setDescription(response.data.description);
      });
    }
  }, [id]);

  return (
    <form
      className="col-sm-12 text-center"
      onSubmit={(e) => {
        handleFormSubmit(e, { url, category, description }, id);
      }}
    >
      <div className="row">
        <div className="input-field">
          <input
            placeholder="URL"
            id="url"
            type="text"
            className="validate foodFormInput"
            name="url"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="input-field">
          <input
            placeholder="Description (if graphic)"
            id="description"
            type="text"
            className="validate foodFormInput"
            name="description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
      </div>
      
      <div className="row">
        <div className="input-field">
          <select
            id="category"
            className=""
            name="category"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option value="" selected>
              Category
            </option>
                  <option value="Picture">Picture</option>
                  <option value="Social">Social</option>
                  <option value="Graphic">Graphic</option>
          </select>
        </div>
        <div className="col-sm-12">
          <button
            className="btn waves-effect waves-light submit-button"
            type="submit"
            name="action"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ExForm;
