import React from 'react';
import { Parallax, Background } from 'react-parallax';
import Pictures from '../../components/SocialMedia/Pictures';
import SocialLanding from '../../components/SocialMedia/SocialLanding';
import QA from '../../components/Website/QA';
import Scenarios from '../../components/Website/Scenarios';
import NavTabs from '../NavTabs';
import './floatPage.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faAddressCard, faTabletAlt, faChartLine, faSignal, faCartPlus, faGift, faBuilding, faBriefcase, faUserTie, faGlasses  } from "@fortawesome/free-solid-svg-icons";

const SocialMedia = () => {
    return (
      <>
        <NavTabs/>
      
        <div className="service-page">
        
            <Parallax bgImage="https://media2.govtech.com/images/940*617/social+media+apps1.jpg" 
        bgImageAlt="the cat" strength={-300} className="parallax-service">
              <ul className="text-center service-landing">
                  <li>
                      <h1 className="bigtxt1">SOCIAL MEDIA and ADS.</h1>
                  </li>
                  <li>
                      <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                  </li>
              </ul>
              
                    

        </Parallax>
     </div>
     <div className="socialmedia-page">
     <Scenarios
        one="You're just starting or your business does not have social media yet"
        two="Your social media is out-dated, isn't performing, or needs to be revamped"
        three="Your brand has grown but your social media accounts haven't evolved"
        four="Your social media looks good, but does not perform"
        five="Managing your current social media is more challenging than should be"
        six="It is time to run advertisements through your social media accounts"
     />


    <div className="background-blue-12"/>
     <div className="row website-content">
         <div className="row text-center">
             <h1 className="why-social-title">TODAY THERE IS ALMOST 4 BILLION SOCIAL MEDIA USERS ONLINE, WHY WOULD YOUR MARKETING STRATEGY NOT INCLUDE SOCIAL MEDIA?</h1>
         </div>
         <div className="row">
             <div className="col-lg-6">
                <Parallax strength={300} className="outerParallax-left">
                    <Background className="parallax-left">
                    <img src='./astronet-logo-new.png' className="graphic-left"/>
                    </Background>
                    <ul className="astroLogo-ul">
                        <li className="text-center">
                        
                        </li>
                    </ul>
                </Parallax>
             </div>
             <div className="col-lg-6">
                <p className="why-social-sub">
                    Nowadays businesses are becoming more connected with Social Media today whether you run a small, medium, or large 
                    enterprise. There are almost 4 billion users online on a daily basis that use 
                    social media for entertainment, communication, purchasing & selling, 
                    learning & teaching, finding services, and multiple other things.
                    <br/><br/>
                    Are you taking advantage of the power of social media to boost you brands identity, customer outreach, and ultimately scale your business?
                    <br/><br/>
                    Facebook alone has more than a billion people connecting to it. 
                    Instagram, Snapchat, LinkedIn, Twitter, Google and 
                    many other social media platforms are surfed daily 
                    by your future clients/customers! At Astronet Digital our team of Social Media Managers working with our Graphic Design, and/or Photography Team offer a one-stop 
                    solution to boost your business’s social media presence and 
                    turn your business into a well known brand.
                </p>
             </div>
         </div>
     </div>


     <div className="background-blue-14"/>
     <div className="website-content-4">
         <div className="social-graphic-outside text-center text-light">
             <div className="row text-center">
             
                 <h1 className="social-graphic-title">5 Facts About Social Media Marketing</h1>
             </div>
             <div className="row facts-row">
                 <div className="col-3">
                    <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faAddressCard} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                 
                <p className="social-graphic-text">Today just over 75% of all users on the internet use social media for various uses daily.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faTabletAlt} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faChartLine} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">The number of worldwide social media users is projected to grow past 4 billion in 2021.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faSignal} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faCartPlus} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">50% of shoppers claim to have bought a product or service based on recommendations they receive via their social media networks.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faGift} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faBuilding} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">96% of small business owners now use social media marketing and 92% of them agree that this type of marketing is 'very important' to them. </p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faUserTie} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 </div>
                 <div className="row facts-row">
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-left">
                        <Background className="floatP-left">
                        <FontAwesomeIcon icon={faBriefcase} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 <div className="col-6 text-center social-text-row">
                     <p className="social-graphic-text">Over 700 Million people look at local business pages on Facebook per week.</p>
                 </div>
                 <div className="col-3">
                 <Parallax strength={300} className="floatParallax-right">
                        <Background className="floatP-right">
                        <FontAwesomeIcon icon={faGlasses} className="oneIcon"/>
                        </Background>
                        
                    </Parallax>
                 </div>
                 
                 </div>
         </div>
     </div>


     <div className="background-blue-15"/>
     <div className="website-content-5">
         <Pictures/>
     </div>
   

     
     <div className="website-content-5">
         <div className="row socialmedia-bottom-row">
             <div className="col-lg-6">
                <Parallax strength={300} className="outerParallax-left">
                    <Background className="parallax-left">
                    <img src='./Icons/1.png' className="graphic-left"/>
                    </Background>
                    <ul className="astroLogo-ul">
                        <li className="text-center">
                        
                        </li>
                    </ul>
                </Parallax>
             </div>
             <div className="col-lg-6  order-first order-lg-last">
             <div className="row">
                     <h3 className="socialmedia-bottom">Do you need social media marketing?</h3>
                 </div>
                 <div className="row">
                     <p className="socialmedia-bottom-text">In todays world, all business should have a social media marketing strategy. Although not all business need one, each and every one could benefit from one. Social Media boost brand identity, costumer outreach, sales, and help scale your company.</p>
                 </div>
                 <div className="row">
                     <h3 className="socialmedia-bottom">Need content to post?</h3>
                 </div>
                 <div className="row">
                     <p className="socialmedia-bottom-text">Look no further! Our skilled team of Social Media content creators can create posts with text, images, videos and even animations and graphics.</p>
                 </div>
                 <div className="row">
                     <h3 className="socialmedia-bottom">Need ADS?</h3>
                 </div>
                 <div className="row">
                     <p className="socialmedia-bottom-text">Astronet's social media managers can run calculated adds targeted at YOUR specific target market.</p>
                 </div>
                 <div className="row">
                     <ul className="text-center">
                         <li className="p-3 m-3">
                            <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                         </li>
                     </ul>
                 </div>
             </div>
         </div>
     </div>


     <div className="background-blue-16"/>
     <div className="website-content-3">
     <QA
         
         one="How does pricing work?"
         oneA="For Social Media account management there will be a monthly fee based on: What/How many platforms you want,  How often you want posts, What kind of posts you want, and if you want Ads."

         two="What Platforms can we manage accounts on?"
         twoA=" We offer management for all platforms of social media. This includes but is not limited to Facebook, Instagram, Twitter, Youtube, LinkedIN, Snapchat, and Tik Tok."

         three="What Kinds of posts can we create?"
         threeA=" With the help of our Graphic Design Team we are able to create custom graphics/visuals including your own colorway, brand designs, and custom logo stamped on each post. With the help of our Photography/Videography Team we can take high quality Pictures and Videos of your business to post as well."

         four="What Kinds of Advertisements do we run?"
         fourA="We run Facebook Multimedia Advertisements (Facebook and Instagram). Facebook Advertisements are Posts that you pay to broadcast to users."
         
         five="How do Ads work?"
         fiveA="Advertisements can be run from your Facebook and Instagram. Depending on how much Ad spend you invest in, the Ad will be broadcast to a certain range of people that fit into your target market."

         six="How much do Ads cost?"
         sixA="Facebook Multi-platform Advertisement pricing is based on the amount of outreach you want + a 10% fee, for example $30 + $300/Mo would a be a total of $10 ad spend per day. Just $10 a day should generate at least 1,000+ impressions."

         />
     </div>


     </div>
      </>
    );
};

export default SocialMedia;