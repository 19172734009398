import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route} from "react-router-dom";

import About from "./containers/About/About"
// import NavTabs from "./containers/NavTabs";
import Home from "./containers/Home/Home";
import Website from "./containers/Website/Website";
import GraphicDesign from "./containers/GrapicDesign/GraphicDesign";
import SocialMedia from "./containers/SocialMedia/SocialMedia";
import Contact from "./containers/Contact/Contact";
import GetStarted from "./containers/GetStarted/GetStarted";
import Admin from "./containers/Admin/Admin";
// import Footer from "./containers/Footer";
import Login from "./containers/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import AlertContext from "./utils/alertContext";
import TokenContext from "./utils/tokenContext";
import ClientContext from "./utils/clientTokenContext";
import Response from "./containers/Response/Response";
import axios from "axios";
import jwt from "jsonwebtoken";
import ClientRoute from "./components/ProtectedRoute/ClientRoute";
import ClientLogin from "./containers/Login/ClientLogin";
import ClientHome from "./containers/Client/ClientHome";
import InfoFoot from "./components/Footer/InfoFoot";
import NextStep from "./containers/GetStarted/NextStep";
import ClientManager from "./containers/Admin/ClientManager";
import PortfolioManager from "./containers/Admin/PortfolioManager";
import Account from "./containers/Client/Account";
import ClientContact from "./containers/Client/ClientContact";
import ViewCreations from "./containers/Client/ViewCreations";
import ThisClientManager from "./containers/Admin/ThisClientManager";
import ToastContext from "./utils/toastContext";
import Photo from "./containers/Photo/Photo";
// import Toast from "./utils/toast/Toast.jsx";



const App = () => {
    const [alert, setAlert] = useState({ message: "", type: "" });
    const [token, setToken] = useState("");
    const [toastBool, setToastBool] = useState("");
    const [clientToken, setClientToken] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getJwt = async () => {
          try {
            const response = await axios.post("/api/auth/cookie");
            const token = response.data.token;
            jwt.verify(
              token,
              process.env.REACT_APP_JWT_SIGNATURE,
              (err, decoded) => {
                if (err) {
                  throw new Error(err);
                } else {
                  setToken(token);
                }
              }
            );
          } catch (err) {
            console.log(err);
            const clientResponse = await axios.post("/client/cookie");
            const clientToken = clientResponse.data.clientToken;
            const clientEmail = clientResponse.data.clientEmail;
            console.log(clientEmail);
            jwt.verify(
              clientToken,
              process.env.REACT_APP_JWT_SIGNATURE,
              (err, decoded) => {
                if (err) {
                  throw new Error(err);
                } else {
                  setClientToken(clientToken);
                  setClientEmail(clientEmail);
                }
              }
            ); 
          } finally {
            setLoading(false);
          }
        };
        if (!token) {
          getJwt();
        }
      }, [token || clientToken]);

    
      const login = (token, cb) => {
        setToken(token);
        cb();
      };

      const userLogin = (token, cb) => {
        setClientToken(token);
        cb();
      };
    
      const logout = (cb) => {
        setToken("");
        cb();
      }

      const clientLogout = (cb) => {
        setClientToken("");
        cb();
      }

      const setContinue = () => {
        setToastBool("true");
      }
    
      if (loading) return null;

    return (
        <Router>
            <AlertContext.Provider value={{ ...alert, setAlert: setAlert }}>
            <TokenContext.Provider value={{ token: token, logout: logout }}>
            <ToastContext.Provider value={{ continue: toastBool, setContinue: setContinue}}>
            <ClientContext.Provider value={{ clientToken: clientToken, clientEmail: clientEmail, logout: clientLogout }}>
            {/* <Toast/> */}
            <Route exact path="/" component={Home} />
            <Route exact path="/website" component={Website} />
            <Route exact path="/graphicdesign" component={GraphicDesign} />
            <Route exact path="/socialmedia" component={SocialMedia} />
            <Route exact path="/photo" component={Photo} />
            <Route exact path="/getstarted" component={GetStarted} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/next" component={NextStep} userLogin ={userLogin}/>
            <Route exact path="/response" component={Response} />
            <ProtectedRoute exact path="/admin" component={Admin}/>
            <ProtectedRoute exact path="/admin/manager" component={ClientManager}/>
            <ProtectedRoute exact path="/admin/manager/client" component={ThisClientManager}/>
            <ProtectedRoute exact path="/admin/portfolio-manager" component={PortfolioManager}/>
            <ClientRoute exact path="/client/home" component={ClientHome}/>
            <ClientRoute exact path="/client/account" component={Account}/>
            <ClientRoute exact path="/client/contact" component={ClientContact}/>
            <ClientRoute exact path="/client/view" component={ViewCreations}/>
            <Route exact path="/login" component={(props) => <Login {...props} login={login} />}/>
            <Route exact path="/client/login" component={(props) => <ClientLogin {...props} userLogin ={userLogin} />}/>
            <div className="row background-purple foot text-center">
                <InfoFoot/>
            </div>
            {/* <Footer/> */}
            </ClientContext.Provider>
            </ToastContext.Provider>
            </TokenContext.Provider>
            </AlertContext.Provider>
        </Router>
      );
};

export default App;