import React from 'react';
import { Parallax, Background } from 'react-parallax';
import GraphicLanding from '../../components/GraphicDesign/GraphicLanding';
import ServiceRow from '../../components/GraphicDesign/ServiceRow';
import QA from '../../components/Website/QA';
import Scenarios from '../../components/Website/Scenarios';
import NavTabs from '../NavTabs';
import $ from 'jquery';
import GraphicEx from '../../components/GraphicDesign/GraphicEx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPeopleArrows, faPodcast, faSearchDollar } from "@fortawesome/free-solid-svg-icons";

const GraphicDesign = () => {
    
    const Show1 = () => {
        $('.service-card-row-2').removeClass('show-graphic-cards');
        $('.service-card-row-3').removeClass('show-graphic-cards');
        $('.service-card-row-1').addClass("show-graphic-cards");
    }

    const Show2 = () => {
        $('.service-card-row-1').removeClass('show-graphic-cards');
        $('.service-card-row-3').removeClass('show-graphic-cards');
        $('.service-card-row-2').addClass("show-graphic-cards");
    }

    const Show3 = () => {
        $('.service-card-row-1').removeClass('show-graphic-cards');
        $('.service-card-row-2').removeClass('show-graphic-cards');
        $('.service-card-row-3').addClass("show-graphic-cards");
    }

    return (
        <>
        <NavTabs/>
      
        <div className="service-page">
        
            <Parallax bgImage="https://us.123rf.com/450wm/primagefactory/primagefactory1710/primagefactory171000823/88694580-selective-focus-photo-of-graphic-designer-using-digital-pad-pen-work-.jpg?ver=6" 
        bgImageAlt="the cat" strength={-200} className="parallax-service">
              <ul className="text-center service-landing">
                  <li>
                      <h1 className="bigtxt1">GRAPHIC DESIGN.</h1>
                  </li>
                  <li>
                      <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                  </li>
              </ul>
              
                    

        </Parallax>
        </div>
        <Scenarios 
        one="You're just starting or your business is does not have a Logo or any other Graphics"
        two="Your Logo/Graphics are out-dated, not professional, or need to be revamped"
        three="Your brand has grown but your brand identity hasn't evolved"
        four="Your Logo looks good, but you need other various graphics"
        five="Updating your current brand identity is more challenging than should be"
        six="It is time to scale your business and you need crisp, professional, and eye-catching graphics"
     />
    
    <div className="background-blue-12"/>
     <div className="row website-content">
         <GraphicEx/>
     </div>
  


     <div className="background-blue-14"/>
     <div className="website-content-4">
         <div className="row">
             <div className="col-lg-6">
                <Parallax strength={300} className="outerParallax-left-small">
                    <Background className="parallax-left-small">
                    <FontAwesomeIcon icon={faPeopleArrows} className="graphic-left-small"/>
                    </Background>
                    <ul className="astroLogo-ul">
                        <li className="text-center">
                        
                        </li>
                    </ul>
                </Parallax>
             </div>
             <div className="col-lg-6 graphic-brand-col">
             <h1 className="graphic-brand-title">Brand Engagement</h1>
            <p className="graphic-brand-text">Content that includes images receives 650% higher engagement than text-only content.</p>
         </div>
         </div>
         <div className="row">
             <div className="col-lg-6">
                <Parallax strength={300} className="outerParallax-left-small">
                    <Background className="parallax-left-small">
                    <FontAwesomeIcon icon={faPodcast} className="graphic-left-small"/>
                    </Background>
                    <ul className="astroLogo-ul">
                        <li className="text-center">
                        
                        </li>
                    </ul>
                </Parallax>
             </div>
             <div className="col-lg-6 graphic-brand-col">
             <h1 className="graphic-brand-title">Brand Identity</h1>
            <p className="graphic-brand-text">Brands that are consistently presented are 3 to 4 times more likely to have a known brand identity.</p>
         </div>
         </div>
         <div className="row delete-bottom">
             <div className="col-lg-6">
                <Parallax strength={300} className="outerParallax-left-small">
                    <Background className="parallax-left-small">
                    <FontAwesomeIcon icon={faSearchDollar} className="graphic-left-small"/>
                    </Background>
                    <ul className="astroLogo-ul">
                        <li className="text-center">
                        
                        </li>
                    </ul>
                </Parallax>
             </div>
             <div className="col-lg-6 graphic-brand-col">
             <h1 className="graphic-brand-title">Brand Capital</h1>
            <p className="graphic-brand-text">Brands that offer consistent brand presentation have a 33% average increase in revenue.</p>
         </div>
         </div>
     </div>
    


     <div className="background-blue-15"/>
     <div className="website-content-5">
     <div className="row text-center text-light">
             <h1 className="why-social-title">Why Astronet Graphic Design?</h1>
         </div>
         <div className="row why-astro-graphics-row">
             
             <div className="col-lg-6">
             <p className="why-graphic-text text-light">Graphic design optimizes your marketing efforts across all channels and is key to building a professional brand. Being consistent in your marketing collateral allows your brand to be easily recognizable and allows your customers and clients quickly get familiar with what your company has to offer.
                <br/><br/>
                Are you harnessing the power of Graphic Design to boost your business marketing strategy?
                <br/><br/>
                Nothing grabs the attention of your target market better than crisp, creative, and custom graphics. Therefore we only create 1 of 1 Logos, Animations, Banners, Infographics, Social Media posts, and advertisements. Our custom-tailored Graphics will catch the eyes of your target market, in order to appeal to the highest amount of potential costumers. Already have a Logo? No problem, we also edit and restyle graphics.</p>
             </div>
             <div className="col-lg-6">
                <Parallax strength={300} className="outerParallax-right-graphic">
                    <Background className="parallax-right-graphic">
                    <img src='./Cards/gfx-card.png' className="graphic-right-graphic"/>
                    </Background>
                    <ul className="astroLogo-ul">
                        <li className="text-center">
                        
                        </li>
                    </ul>
                </Parallax>
             </div>
         </div>
     </div>


     <div className="background-blue-16"/>
     <div className="website-content-3">
     <div className="row service-cards-row">
            <div className="row">
                <button className="btn text-light graphic-service-btn" onClick={() => { Show1(); }}>BRAND DESIGN</button>
            </div>
        <ServiceRow 
        number="1"

        title1="Logo Design" title2="Brand Guides" title3="Stationeries"

        text11="Includes a stunning professionally designed logo."
        text12="Logo design targeted towards effectively establishing your brand's identity."
        text13="Fully-customizable with consistent client to designer feedback."

        text21="Includes a comprehensive guide of your brand's fonts, colors, and overall style."
        text22="Custom-tailored by our professional designers to your brand's own identity. "
        text23="Easily shareable format to ensure consistency among your brand's styling."

        text31="Complete your branding with gorgeous custom stationery that keeps you looking polished and professional."
        text32="Includes: letterhead, envelope, note, business card, or stickers, etc.."
        text33="No matter what you have designed, your stationery  will be uploaded in the correct formats based on your stated needs."

        price1="99+" price2="149+" price3="199+"
        />
        </div>
        <div className="row service-cards-row">
        <div className="row">
                <button className="btn text-light graphic-service-btn" onClick={() => { Show2(); }}>PRINT DESIGN</button>
        </div>
        <ServiceRow 
        number="2"

        title1="Postcards / Flyers" title2="Product Packaging" title3="Infographics"

        text11="Make your brand look good while you spread the word with an attention-grabbing flyer design."
        text12="All of our designers are hand-vetted and rated for their quality, so you’ll know you’re working with the best."
        text13="Any style, any industry. Our flyer designers do it all."

        text21="Custom packaging design for your products that generate sales."
        text22="Eye-catching packaging will make your product stand out to consumers."
        text23="You’ll collaborate and give feedback to create the ideal product package."

        text31="Can’t decide whether you need info or graphics? Get the best of both worlds in an infographic."
        text32="Organize data, lists and all sorts of information your customers need in a fun and engaging way."
        text33="Bright illustrations and simple charts turn gobs of information into social-friendly content that your audience will love."

        price1="49+" price2="99+" price3="149+"
        />
        </div>
        <div className="row service-cards-row">
        <div className="row">
                <button className="btn text-light graphic-service-btn" onClick={() => { Show3(); }}>ART & ILLUSTRATIONS</button>
        </div>
        <ServiceRow 
        number="3"

        title1="Social Media Cover Arts" title2="Custom Web Graphics" title3="Social Media Posts"

        text11="Includes custom, professional social media graphics for Facebook, Twitter, YouTube, LinkedIn, Google+ or any other platform."
        text12="Make your marketing go viral with expertly-crafted social media page design built to match your brand."
        text13="Nail that first customer interaction with stunning social media graphics built for your business."

        text21="Includes an intuitive and attractive design that grows engagement exponentially."
        text22="Instead of using stock photos, we'll create custom web illustrations that match and compliment your brand."
        text23="Draw more visitors with website graphics that depict the brand image you want to portray."

        text31="Stylish, modern & professional social media posts for Instagram, Facebook, Twitter, etc."
        text32="Customers begin to recognize your brand through your images and posts on social media."
        text33="Social media posts are customized and branded with your logo and branding details."

        price1="39+" price2="49+" price3="19+"
        />
        </div>
     </div>


     
     <div className="website-content-3">
     <QA
         
         one="How long will it take?"
         oneA="The wait may vary from days to weeks, depending on the complexity and scale of the project. We keep in contact with all clients throughout the design process. However, if you need to meet certain deadlines, we can definitely work with you to meet them."

         two="How will I be involved in the design process?"
         twoA="We pride ourselves in involving our clients in their designs as much as possible. From start to finish, you will be consistently updated every step of the way on all designs. Your feedback will always be used to create the perfect design for your brand."

         three="What software do we use?"
         threeA="Our designers are proficient in a large variety of platforms such as Adobe PhotoShop, Illustrator, XD, InDesign, After Effects, and Cinema4D."

         four="How will I receive content created for me?"
         fourA="For each client, we will provide a google drive link containing all your design files. Additionally, we can export to any format necessary for you to move along with production. The source file will also be provided."

         five="How will I pay for my designs?"
         fiveA="Payment will be accepted via this website! After creating an account, you can log in to pay for and view content we create for you (When the design is completed)."

         six="Are there bundle discounts for multiple assets and designs?"
         sixA="Of course! Please request a quote or email us at team@astronetdigital.com "

         />
     </div>
     
      </>
    );
};

export default GraphicDesign;