import React from 'react';
import ContactLine from '../../components/Contact/ContactLine';
import NavTabs from '../NavTabs';
import AboutPage from './AboutPage';

const About = () => {
    return (
        <>
        <NavTabs/>
        <div className="about-page-outer">
        <AboutPage/>
        </div>
        </>
    );
};

export default About;