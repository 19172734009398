import React, { useState, useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import AlertContext from "../../utils/alertContext";
import jwt from "jsonwebtoken";
import NavTabs from "../NavTabs";

const ClientLogin = ({userLogin}) => {
    
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const alert = useContext(AlertContext);
  const history = useHistory();
  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/client/login", { email, password })
      .then((response) => {
        console.log(response.data);
        jwt.verify(
          response.data.clientToken,
          process.env.REACT_APP_JWT_SIGNATURE,
          (err, decoded) => {
            if (err) {
              console.log(err);
            } else {
              userLogin(response.data.clientToken, () => {
                history.push({
                  pathname: "/client/home",
                  state: {clientEmail: email}
                });
                history.go(0);
              });
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        alert.setAlert({
          message: "Incorrect Username or Password please try again!",
          type: "danger",
        });
      });
  };
  return (
    <>
    <NavTabs/>
    <div className="container login-block">
    <p className="text-center">{alert.message}</p>
    <form onSubmit={(e) => {
          handleFormSubmit(e);
        }}> 
        <div className="row py-3 my-3">
                <h1 className="text-center">LOGIN</h1>
            </div>
            <div className="row login-input py-3 my-3">
                <label htmlFor="name" className="col-form-label purple">Email</label>
                <div className="row">
                <input type="email" className="form-control astroForm-white" id="email" name="email" value={email} onChange={(e) => {setEmail(e.target.value);}}/>
                </div>
            </div>
            <div className="row login-input mb-3">
                <label htmlFor="email" className="col-form-label purple">Password</label>
                <div className="row">
                <input type="password" className="form-control astroForm-white" id="password" name="password" value={password} onChange={(e) => {setPassword(e.target.value);}}/>
                </div>
            </div>
            
            <div className="row pt-3 mt-3">
                <button type="submit" className="btn sub-btn login-btns">Login</button>
            </div>
            <div className="row login-as-row">
              <div className="col-6 text-center">
                <a href='/getstarted' className="sign-a ">Sign Up</a>
              </div>
              <div className="col-6 text-center">
                <a  href='/forgotpassword' className="forgot-a ">Forgot Password</a>
              </div>
                </div>
        </form>
    </div>
    </>
  );
};

export default ClientLogin;
