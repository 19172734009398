import React from 'react';

function AboutPage(props) {
    return (
        <div>
            <div className="about-page">
            <div className="row text-center">
                <ul className="justify-content-center">
                    <li>
                      <img src="astronet-logo-new.png" className="aboutLogo"/>  
                    </li>
                </ul>
                
            </div>
            <div className="row text-center">
                <p className="about-text">Welcome to Astronet Digital, a Premier Digital Marketing Agency based out of Atlanta, Ga. Our team is comprised of experienced professionals in various digital fields, such as Web Development, Graphic Design, Digital Marketing, Photography and Editing. The digital world is always changing and advancing, therefore we strive to advance our skills with each project, using new tactics and features. Thus, garenteeing that the content we create will stand out far from average. Our goal with each project we take on is to professionalize your digital presence in order to grow your business to new heights.</p>
            </div>
            
        </div>
        </div>
    );
}


export default AboutPage;