import React from 'react';
import ClientNav from '../ClientNav';
import Form from '../GetStarted/Form';
import ClientForm from './ClientForm';

function ClientContact(props) {
    return (
        <div>
            <ClientNav/>
            <ClientForm/>
        </div>
    );
}

export default ClientContact;