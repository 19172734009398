import React, {useState, useEffect, useContext} from 'react';
import ClientNav from '../ClientNav';
import MPayment from './MPayment';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useHistory, useLocation} from 'react-router-dom';
// Styles
import './styles.css';
import axios from 'axios';
import PayBox from './PayBox';
import ClientTokenContext from '../../utils/clientTokenContext.js'
// pk_live_51IaOAKA9dPL5D36Y2blIpVckw15qudZwjiRosLJij2ZqcDZaDnmdQSoQok4F9VokzAn251G0HhM7QYK7aOh6FHRU00UJtxhEjf
const stripePromise = loadStripe('pk_test_51IaOAKA9dPL5D36YEime68aU1bYSYjRktFX9EYd59nPwxVAo4BCGpLYATzMpbViHiphuiJ1A7XORTga9SThck0Ov00hCMAN5Iu');

function ClientHome(props) {
    
    const {clientEmail} = useContext(ClientTokenContext);
    const [thisClient, setThisClient] = useState("");
    const history = useHistory();
    const location = useLocation();

  useEffect(() => {
      axios.get("/api/response/email/" + clientEmail)
      .then(response => {
          setThisClient(response.data[0]);
      });
      console.log(thisClient);
  }, [setThisClient]);

    if (thisClient.moneyOwed <= 0 & thisClient.subscription == null) { return (
       <>
            <ClientNav/>
            <div className="client-page">
            <h1 className="text-center orange clientIframe">Nothing to see yet!</h1>
            </div>
       </>
    )} else if ( thisClient.moneyOwed > 0 & thisClient.subscription !== null) { return (
        <>
        <ClientNav/>
        <div className="client-page">
        <iframe src={thisClient.iFrame} className="clientIframe"/>
            <Elements stripe={stripePromise}>
                <MPayment {...thisClient} email={clientEmail}/>
            </Elements>
            <Elements stripe={stripePromise}>
                <PayBox moneyOwed={thisClient.moneyOwed} email={clientEmail} {...thisClient}/>
            </Elements>
        </div>
    </>
    )} else if ( thisClient.moneyOwed <= 0 & thisClient.subscription !== null) { return (
        <>
        <ClientNav/>
        <div className="client-page">
        <iframe src={thisClient.iFrame} className="clientIframe"/>
            <Elements stripe={stripePromise}>
                <MPayment {...thisClient} email={clientEmail}/>
            </Elements>
        </div>
    </>
    )} else if ( thisClient.moneyOwed > 0 & thisClient.subscription == null) { return (
        <>
        <ClientNav/>
        <div className="client-page">
        <iframe src={thisClient.iFrame} className="clientIframe"/>
            <Elements stripe={stripePromise}>
                <PayBox moneyOwed={thisClient.moneyOwed} email={clientEmail} {...thisClient}/>
            </Elements>
        </div>
    </>
    )} else { return (
        <> 
        <ClientNav/>
        <div className="client-page">
        <h1 className="text-center orange">Nothing to see yet!</h1>
        </div>
       </>
    )}


}




export default ClientHome;

