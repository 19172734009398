import axios from "axios";
import React, {useContext, useState, useEffect} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import API from "../utils/API";
import ClientTokenContext from "../utils/clientTokenContext";

function ClientNav() {
  // We'll go into the Hooks API later, for now, we are just using some code
  // from the react-router docs (https://reacttraining.com/react-router/web/api/Hooks/uselocation)
  // This allows the component to check the route any time the user uses a link to navigate.
  const location = useLocation();
  const {logout, clientToken, clientEmail} = useContext(ClientTokenContext);
  const history = useHistory();

  const [thisClient, setThisClient] = useState("");
 
  useEffect(() => {
      axios.get("/api/response/email/" + clientEmail)
      .then(response => {
          console.log(response.data);
          setThisClient(response.data[0]);
      });
      
  }, [setThisClient]);

  const logOutUser = () => {
    logout(() => {
      history.push("/");
      API.logoutUser();
    })
  }

  const account = () => {
    history.push({
        pathname: `/client/account`,
        state: {thisClient: thisClient}
    });
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light background-purple sticky-top">
        <div className="container-fluid">
          <a href="/client/home" className="navbar-brand">
            <img
              src="../astrotxt.png"
              className="d-inline-block align-top astrologo"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <Link
                  to="/client/home"
                  aria-current="page"
                  className={
                    location.pathname === "/client/home"
                      ? "nav-link orange"
                      : "nav-link text-light"
                  }
                >
                  DASHBOARD
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/client/contact"
                  aria-current="page"
                  className={
                    location.pathname === "/client/contact"
                      ? "nav-link orange"
                      : "nav-link text-light"
                  }
                >
                  CONTACT
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/client/view"
                  aria-current="page"
                  className={
                    location.pathname === "/client/view"
                      ? "nav-link orange"
                      : "nav-link text-light"
                  }
                >
                  VIEW CREATIONS
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav">
            <li className="nav-item clientsName" onClick={()=>account()}>
                {thisClient.firstName} {thisClient.lastName}
            </li>
            <li className="nav-item">
            <button className="logoutBtn" onClick={()=>logOutUser()}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default ClientNav;
