import React from 'react';

const ServiceCard = (props) => {
    return (
        <div className="col-md-4 ">
            <div className="graphic-service-card">
                <h3 className="text-center text-light graphic-service-card-title">{props.title}</h3>
                {/* <p className="text-left text-light graphic-service-card-text">{props.text}</p> */}
                <ul>
                    <li className="text-left text-light graphic-service-card-text">{props.text1}</li>
                    <li className="text-left text-light graphic-service-card-text">{props.text2}</li>
                    <li className="text-left text-light graphic-service-card-text">{props.text3}</li>
                </ul>
                <div className="row graphic-service-card-row-bottom">
                    <div className="col-6">
                        <p className="text-center text-light graphic-service-card-price">${props.price}</p>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-light"><a href="/getstarted" className="btn-light-text">Get Started</a></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceCard;