import React from 'react';
import ServiceCard from './ServiceCard';
import './graphic.css';

const ServiceRow = (props) => {

   

    return (
        <>
            
            <div className={`row service-card-row-${props.number}`}>
            <ServiceCard title={props.title1} text1={props.text11} text2={props.text12} text3={props.text13} price={props.price1}/>
            <ServiceCard title={props.title2} text1={props.text21} text2={props.text22} text3={props.text23} price={props.price2}/>
            <ServiceCard title={props.title3} text1={props.text31} text2={props.text32} text3={props.text33} price={props.price3}/>
            </div>
        
        </>
    );
}

export default ServiceRow;