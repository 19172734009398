import React from 'react';
import { Parallax, Background } from 'react-parallax';
import IPhone from '../../components/Website/IPhone.jsx';
import QA from '../../components/Website/QA.jsx';
import Scenarios from '../../components/Website/Scenarios.jsx';
import NavTabs from '../NavTabs';

const Website = () => {
    return (
        <>
        <NavTabs/>
      
        <div className="service-page">
        {/* <Parallax strength={300} className="outerParallax-service">
            <Background className="parallax-service">
                <img src="https://i.pinimg.com/originals/f9/ba/36/f9ba36b9b4c89ea9a57a6a18a96d0a0a.jpg" alt="fill murray" />
            </Background>
            <h1 className="bigtxt1">WEB DEV.</h1>
                    <h1 className="landsubtxt1">WE SPECIALIZE IN CREATING CUSTOM WEBSITES</h1>
                    <p className="landsubtxt2">At AstroNET Digital we do full-stack web development. We specialize in UX/UI development,  
                    <br/> graphic design, custom layouts, and backend functionalities, such as Admin Portals, 
                    <br/> User Interactive Features, Response Forms, Scheduling and more. Our custom-tailored
                    <br/> solutions are complete with all the necessary tools your business wants and needs. 
                    <br/> Experience lightyears of digital growth in a matter of weeks!  
                    </p>
                    <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
            </Parallax> */}
            <Parallax bgImage="https://i.pinimg.com/originals/f9/ba/36/f9ba36b9b4c89ea9a57a6a18a96d0a0a.jpg" 
        bgImageAlt="the cat" strength={-300} className="parallax-service">
              <ul className="text-center service-landing">
                  <li>
                      <h1 className="bigtxt1">WEB DEV.</h1>
                  </li>
                  <li>
                      <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                  </li>
              </ul>
        </Parallax>
     </div>
     
     
     <Scenarios 
        one="You're just starting or your business is not yet online"
        two="Your website is out-dated, broken, or needs to be revamped"
        three="Your brand has grown but your website hasn't evolved"
        four="Your website looks good, but does not do things you need it to"
        five="Updating your current site is more challenging than should be"
        six="It is time to scale your business and add an extra sales channel"
     />
     
    
     <div className="background-blue-12"/>
     <div className="row website-content">
        <div className="col-md-6 mobile-content">
             <h1 className="mobile-title">Mobile First Design</h1>
             <p className="mobile-text">Globally, 68% of website traffic in 2020 came 
                 from mobile devices, an increase from 63% in 2019. 
                 In 2021, it's safe to say that more likely than not, your
                 website is going to be visited via a mobile device.
                 Unlike most Digital Agencies, at Astronet Digital we
                 pride ourselves on mobile-first design. This means 
                 we build our websites to fit a mobile device, and then 
                 restyle the website to fit a computer screen if viewed via computer.
            </p>
            <div className="row mobile-row">
                <div className="col-sm-6 text-center mobile-div">
                    <img src="../Web/mobile-1.png" className="mobile-ss"/>
                </div>
                <div className="col-sm-6 text-center mobile-div">
                    <img src="../Web/mobile-2.png" className="mobile-ss"/>
                </div>
            </div>
         </div>
        <IPhone src="https://www.astronetdigital.com"/>
     </div>
     <div className="background-blue-14"/>
     <div className="website-content-4">
         <div className="row text-center pb-3 mb-3">
             <h1 className="orange">
                 Landing/Showcase Application
             </h1>
         </div>
         <div className="row landing-row">
             <div className="col-md-6">
                <IPhone src="https://jamalares.com"/>
             </div>
             <div className="col-md-6 text-light">
             <div className="row text-center">
                     <h3 className="web-sub">Do you need a website?</h3>
                 </div>
                 <div className="row text-center">
                     <p className="web-sub-text">In todays world, all business should have a website. Although not all business need one, each and every one could benefit from one. Websites boost brand identity, costumer outreach, sales, and help scale your company.</p>
                 </div>
                 <div className="row text-center">
                     <h3 className="web-sub">Need a landing page?</h3>
                 </div>
                 <div className="row text-center" >
                     <p className="web-sub-text">Look no further! Our skilled team of web developers can create dynamic landing pages in 3 days or less.</p>
                 </div>
                 <div className="row text-center">
                     <h3 className="web-sub">Need more than one page?</h3>
                 </div>
                 <div className="row text-center">
                     <p className="web-sub-text">We can create multi-page web applications to showscase your biz in a 5-15 days, depending on amount of pages.</p>
                 </div>
                 <div className="row text-center">
                     <h3 className="web-sub">Do you want a place where your sites visitors can send you messages?</h3>
                 </div>
                 <div className="row text-center">
                     <p className="web-sub-text">Our skilled team of web developers can add a response box that will send you emails when someone responds!</p>
                 </div>
                 <div className="row text-center">
                     <h3 className="web-sub">Need more?</h3>
                 </div>
                 <div className="row text-center">
                     <p className="web-sub-text">Read below for more functionalities!</p>
                     <ul className="text-center">
                         <li className="p-3 m-3 web-btn">
                            <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
                         </li>
                     </ul>
                 </div>
             </div>
         </div>
     </div>
     <div className="background-blue-15"/>
     <div className="website-content-5">
         <div className="row text-center">
             <h1 className="orange pb-3 mb-3">Scheduling Application</h1>
         </div>
         <div className="row text-center p-3 m-3">
             <h5 className="sched-text-top">
                 Appointment Scheduling solutions can be implemented into your website if desired. Our team of web developers will work to find the custom solution that fits your business model best.
             </h5>
         </div>
         <div className="row">
             <div className="col-sm-6">
                <Parallax strength={300} className="outerParallax-web">
                    <Background className="parallax-web">
                        <img src="../Web/calendar.png" className="graphic-web"/>
                    </Background>
                </Parallax>
             </div>
             <div className="col-sm-6 text-center">
             <h1 className="sched-title">Calendar</h1>
             <p className="sched-text">Does your business need a calendar feature? Calendar features can be used to Display Events, Appointment Scheduling, and more. Certain dates can be pre-blocked (not click-able) if desired, often used to block weekends for Appointment Scheduling. Whatever use your business needs this function for, each date block will open a page with the data of that date. 
            </p>
             </div>
         </div>
         <div className="row">
             <div className="col-sm-6 text-center">
             <h1 className="sched-title-right">Appointment</h1>
             <p className="sched-text-right">Do your clients/customers need to book an Appointment? If so, we offer custom appointment solutions, including Time of day or Time Frame, and/or choice of what they are booking. All appointments are dynamically rendered, meaning only available slots will appear to your websites visitors.
            </p>
             </div>
             <div className="col-sm-6 order-first order-sm-last">
                <Parallax strength={300} className="outerParallax-web-right">
                    <Background className="parallax-web-right">
                        <img src="../Web/appt.png" className="graphic-web-right"/>
                    </Background>
                </Parallax>
             </div>
         </div>
         <div className="row">
             <div className="col-sm-6">
                <Parallax strength={300} className="outerParallax-web">
                    <Background className="parallax-web">
                        <img src="../Web/admin.png" className="graphic-web"/>
                    </Background>
                </Parallax>
             </div>
             <div className="col-sm-6 text-center">
             <h1 className="sched-title">Appointment Manager</h1>
             <p className="sched-text">No matter what use your business has for our Appointment Scheduling software, all Applications that implement it will also include an Appointment Manager Page. This page can only be accessed by your team, for you will set an email and password used to login as an Admin on your site to view all appointments scheduled. You will be able to decline or accept any appointment, this will update the client via email message.
            </p>
             </div>
         </div>
         <div className="row">
             <div className="col-sm-6 text-center">
             <h1 className="sched-title-right">Ordering</h1>
             <p className="sched-text-right">Don't need scheduling but your clients still need to interact with your site? We also offer Ordering Functionalities, this includes a list of Products that can be addedd to cart and ordered. You will be able to securely login and view all orders.
            </p>
             </div>
             <div className="col-sm-6 order-first order-sm-last">
                <Parallax strength={300} className="outerParallax-web-right">
                    <Background className="parallax-web-right">
                        <img src="../Web/mobile-2.png" className="graphic-web-right"/>
                    </Background>
                </Parallax>
             </div>
         </div>
         <div className="row text-center ordering-btn p-3 m-3">
             <h3 className="orange">
                 eCommerce Solutions can also be implemented into your website if needed.
             </h3>
             <li className="py-3 my-3 ">
                      <a className="quoteRecBtn" href="/getstarted">REQUEST A QUOTE</a>
            </li>
         </div>
     </div>
     <div className="background-blue-16"/>
     <div className="website-content-3">
         <QA

         one="Is there a monthly Hosting & Service fee?"
         oneA="Yes, Hosting & Service fees start at $40/Mo, for basic applications. Applications that include extra functionalities will cost more per month, depending on witch services are desired and amount of changes needed done to the site per month."

         two="How long will my website take to be coded?"
         twoA="Because we code all websites from scratch, the amount of time depends on each website. Generally a one page site will take less than 3 days, for multiple pages you can expect to wait about 1-3 days per page. If your website is going to include extra functionalities such as scheduling, appointment, or eCommerce, you will be informed of an expected wait time."

         three="How do I pay for services?"
         threeA="To pay for any services you must create an account, this can be done by locating the 'Get Started' page on this site, this will create an account that you can login to, here you will see any due payments, information on your Hosting Subscription and any content we have created for you."

         four="Will I get to choose my Domain?"
         fourA="Although, not all Domains are available, more than likely we will be able to get the Domain name you desire. If you already have a domain, we can transfer it to your new website."

         five="How much input will I get on how my site looks?"
         fiveA="Its up to you! When logged in, you will be able to see your site being built, you can let us know what you like, don't like, or what you might want changed."

         six="How do we make Websites?"
         sixA="Our team of Web-Developers code all websites with Reactjs. React is a fairly new coding language, but it is one of the most popular today. React was used to code INSTAGRAM, FACEBOOK, HOME DEPOT .COM and many more well known websites. We choose to use React because of it's ability to process and update data very quickly."

         />
     </div>
            
      </>
    );
};

export default Website;